<template>
  <div class="process-wrapper">
    <div>
      <div v-if="!claimed">
        <TaskBlocker :assignee="data.assignee" />
      </div>
      <div v-if="!isLoading && products">
        <display-container class="my-4">
          <div class="details">
            <data-display label="Kundennummer">
              <a
                target="_blank"
                :href="`/support-start?page=Kunden%C3%BCbersicht&system=VarioCRM&customerId=${customerId}`"
              >
                {{ customerId }}
              </a>
            </data-display>
            <data-display label="Vertragsnummer">
              <span>{{ contractId }}</span>
            </data-display>
          </div>
        </display-container>
        <display-container label="Gebuchte Produkte">
          <v-alert
            elevation="2"
            type="info"
            text
          >
            <span>Der Vertrag hat vermutlich kein Premium-<strong>Glasfaser</strong>-Router als Produkt</span>
          </v-alert>
          <all-ordered-products-table :allProducts="products" />
        </display-container>
        <div class="buttons-wrapper">
          <v-btn
            :disabled="isLoading || !claimed"
            color="primary"
            large
            @click="completeTask()"
          >
            Task abschließen
          </v-btn>
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ActionValidateRouterProduct',
  components: {
    TaskBlocker: () => import('../TaskBlocker.vue'),
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
    DisplayContainer: () =>
      import('@/components/elements/DisplayContainer.vue'),
    Loader: () => import('@/components/Loader.vue'),
    AllOrderedProductsTable: () => import('@/components/AllOrderedProductsTable.vue'),
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      customerId: null,
      contractId: null,
      products: null,
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
  },
  mounted: function () {
    this.loadTaskData();
  },
  methods: {
    loadTaskData: async function () {
      this.isLoading = true;
      await this.data.variables.then((data) => {
        this.customerId = data.customerId.value;
        this.contractId = data.contractId.value;
        this.loadProducts();
        this.isLoading = false;
      });
    },
    loadProducts: function () {
      return HTTP.get(`/products/contract/all/${this.contractId}`).then((resp) => {
        this.products = resp.data;
      });
    },
    completeTask: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {})
        .then(() => {
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showNotification(
            'error',
            'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!'
          )
        );
    },
    showToast(message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    }
  }
};
</script>

<style scoped>
.details {
  display: grid;
  grid-template-columns: calc(50% - var(--goe-spacing-0_5)) calc(
      50% - var(--goe-spacing-0_5)
    );
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}
</style>
